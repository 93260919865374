import React, { useEffect, useState } from 'react'
import s from './BottomSide.module.sass'
import axios from '../../axios'
import { useNavigate } from 'react-router-dom'
function BottomSide() {

  const [contact, setContact] = useState(false)
  const [terms, setTerms] = useState(false)

  const [contactText, setContactText] = useState('')
  const [termText, setTermText] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    axios.get('/getContact')
    .then((res) => res.data)
    .then(data => {
      if(data){
        setContactText(data.text.replace(/\n/g, '<br>'))
      }
    })
    axios.get('/getTerms')
    .then((res) => res.data)
    .then(data => {
      if(data){
        setTermText(data.text.replace(/\n/g, '<br>'))
      }
    })
  }, [])

  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <div className={s.circles}></div>
            <img className={s.logo} src="/images/Group 12 (1).svg" alt="" />
            
            <div className={s.footer}>
                <img src="/images/logo.svg" style={{cursor: "pointer"}} onClick={() => window.location.reload()} alt="" />
                <a onClick={() => {setContact(true); console.log('click')}}>Contact</a>
                <a onClick={() => navigate('/')}>CryptoVote©</a>
                <a onClick={() => {setTerms(true); console.log('click')}}>Terms</a>
                <a href='#whitepaper11'>Whitepaper</a>
                <a href="https://x.com/CryptoVoteUS" target="_blank" rel="noopener noreferrer">X</a>
<a href="https://t.me/CryptoVoteUS" target="_blank" rel="noopener noreferrer">Telegram</a>
<a href="https://medium.com/@CryptoVoteUS" target="_blank" rel="noopener noreferrer">Medium</a>
<a href="https://coinmarketcap.com/community/profile/CryptoVoteUS/" target="_blank" rel="noopener noreferrer">CoinMarketCap</a>

                {/* <a>Discord</a> */}
            </div>
            <div className={s.gradient}></div>

            {
              contact == true &&
              <div className={s.contact}>
                <img className={s.close} onClick={() => setContact(false)} src="/images/delete-sign.png" alt="" />
                <div className={s.title}>CONTACT</div>
                <div className={s.par} dangerouslySetInnerHTML={{ __html: contactText }}></div>
              </div>
            }
            {
              terms == true &&
              <div className={s.contact}>
                <img className={s.close} onClick={() => setTerms(false)} src="/images/delete-sign.png" alt="" />
                <div className={s.title}>TERMS</div>
                <div className={s.par} dangerouslySetInnerHTML={{ __html: termText }}></div>
              </div>
            }
        </div>
    </div>
  )
}

export default BottomSide