import React, { useEffect, useState } from 'react'
import s from './Peoples.module.sass'
import axios from '../../axios'
import ReactGA from 'react-ga4'

function Peoples() {

  const [coef, setCoef] = useState({
    time1: "",
    price1: "",
    time2: "",
    price2: ""
  })
  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const [prev1, setPrev1] = useState()
  const [prev2, setPrev2] = useState()

  const [link1, setLink1] = useState()
  const [link2, setLink2] = useState()

  const [deadline, setDeadline] = useState()

  useEffect(() => {
    axios.get('/getDate')
    .then(res => res.data)
    .then(data => setDeadline(data.date))
  }, [])


  useEffect(() => {
    // Функция для вычисления оставшегося времени
    const calculateTimeLeft = () => {
      const now = new Date();
      const deadlineDate = new Date(deadline);

      const difference = deadlineDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({
          days: String(days).padStart(2, '0'),
          hours: String(hours).padStart(2, '0'),
          minutes: String(minutes).padStart(2, '0'),
          seconds: String(seconds).padStart(2, '0'),
        });
      } else {
        setTimeLeft({
          days: '00',
          hours: '00',
          minutes: '00',
          seconds: '00',
        });
      }
    };

    // Обновляем таймер каждую секунду
    const timerId = setInterval(calculateTimeLeft, 1000);

    // Очищаем таймер при размонтировании компонента
    return () => clearInterval(timerId);
  }, [deadline]);


  useEffect(() => {
    const fetchTrades = async (adres1, adres2) => {
      const now = new Date();
      const fifteenMinutesAgo = new Date(now.getTime() - 5 * 60000);
    
      const tillTime = now.toISOString(); // текущее время
      const sinceTime = fifteenMinutesAgo.toISOString(); // время 15 минут назад
    
      const query = `
        query MyQuery {
          Solana {
            DEXTradeByTokens(
              where: {
                Trade: {Currency: {MintAddress: {is: "${adres1}"}}},
                Block: {Time: {since: "${sinceTime}", till: "${tillTime}"}}
              }
              limit: {count: 1}
            ) {
              Block {
                Time
              }
              Trade {
                Currency {
                  Name
                  Symbol
                }
                Amount
                PriceAgainstSideCurrency: Price
                PriceInUSD
              }
            }
          }
        }
      `;
      const query2 = `
        query MyQuery {
          Solana {
            DEXTradeByTokens(
              where: {
                Trade: {Currency: {MintAddress: {is: "${adres2}"}}},
                Block: {Time: {since: "${sinceTime}", till: "${tillTime}"}}
              }
              limit: {count: 1}
            ) {
              Block {
                Time
              }
              Trade {
                Currency {
                  Name
                  Symbol
                }
                Amount
                PriceAgainstSideCurrency: Price
                PriceInUSD
              }
            }
          }
        }
      `;
    
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-API-KEY", "BQY5aq8APGbaBpByE8jKu3EPVTgIvG7c");
      myHeaders.append("Authorization", "Bearer ory_at_YamYsAO7b9jEBnXGI8-2kJekEwimTxLLpHXU7z7pglY.3t25YHD831pmzeLj23N3bGVNY4WNMYxAWFMNYK7RTEY");
    
      const raw = JSON.stringify({ query });
      const raw2 = JSON.stringify({ query2 });
    
      const requestOptions2 = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
    
      try {
        const response2 = await fetch("https://streaming.bitquery.io/eap", requestOptions2);
        const result2 = await response2.json();

        const response = await fetch("https://streaming.bitquery.io/eap", requestOptions);
        const result = await response.json();

        setPrev1(result.data.Solana.DEXTradeByTokens[0].Trade.PriceInUSD)
        setPrev2(result2.data.Solana.DEXTradeByTokens[0].Trade.PriceInUSD)

        console.log("15 min 1", result.data.Solana.DEXTradeByTokens[0]);
        console.log("15 min 2", result2.data.Solana.DEXTradeByTokens[0]);
      } catch (error) {
        console.error("Ошибка:", error);
      }
    };
    
    // Вызов функции для получения данных

    const fetchData = async (adres1, adres2) => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-API-KEY", "BQY5aq8APGbaBpByE8jKu3EPVTgIvG7c");
        myHeaders.append("Authorization", "Bearer ory_at_YamYsAO7b9jEBnXGI8-2kJekEwimTxLLpHXU7z7pglY.3t25YHD831pmzeLj23N3bGVNY4WNMYxAWFMNYK7RTEY");
  
        const rawQuery1 = JSON.stringify({
          "query": `query MyQuery {\n  Solana {\n    DEXTradeByTokens(\n      where: {Trade: {Currency: {MintAddress: {is: \"${adres1}\"}}}, Block: {}}\n      limit: {count: 1}\n      orderBy: {descending: Block_Time}\n    ) {\n      Block {\n        Time\n      }\n      Trade {\n        Currency {\n          Name\n          Symbol\n        }\n        Amount\n        PriceAgainstSideCurrency: Price\n        PriceInUSD\n      }\n    }\n  }\n}\n`,
          "variables": "{}"
       });
  
        const rawQuery2 = JSON.stringify({
          "query": `query MyQuery {\n  Solana {\n    DEXTradeByTokens(\n      where: {Trade: {Currency: {MintAddress: {is: \"${adres2}\"}}}, Block: {}}\n      limit: {count: 1}\n      orderBy: {descending: Block_Time}\n    ) {\n      Block {\n        Time\n      }\n      Trade {\n        Currency {\n          Name\n          Symbol\n        }\n        Amount\n        PriceAgainstSideCurrency: Price\n        PriceInUSD\n      }\n    }\n  }\n}\n`,
          "variables": "{}"
       });
  
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow"
        };
  
        // Параллельное выполнение запросов
        const [result1, result2] = await Promise.all([
          fetch("https://streaming.bitquery.io/eap", { ...requestOptions, body: rawQuery1 }),
          fetch("https://streaming.bitquery.io/eap", { ...requestOptions, body: rawQuery2 })
        ]);
  
        const data1 = await result1.json();
        const data2 = await result2.json();
  
        console.log('Result 1:', data1.data.Solana.DEXTradeByTokens[0]);
        console.log('Result 2:', data2.data.Solana.DEXTradeByTokens[0]);
        setCoef({
          time1: data1.data.Solana.DEXTradeByTokens[0].Block.Time,
          time2: data2.data.Solana.DEXTradeByTokens[0].Block.Time,
          price1: data1.data.Solana.DEXTradeByTokens[0].Trade.PriceInUSD,
          price2: data2.data.Solana.DEXTradeByTokens[0].Trade.PriceInUSD,
        });
      } catch (error) {
        console.error('Ошибка:', error);
      }
    };
  
    // Вызов fetchData сразу при загрузке компонента
    
    axios.get('/getCoin')
      .then(res => res.data)
      .then(data => {
        fetch(`https://api.dexscreener.com/latest/dex/pairs/solana/${data.price1}`)
        .then(data => data.json())
        .then(res => {
          setCoef(coef => ({
            ...coef,
            price1: res.pair.priceUsd
          }));      
          setPrev1(res.pair.priceChange.m5)    
          
        })
        fetch(`https://api.dexscreener.com/latest/dex/pairs/solana/${data.price2}`)
        .then(data => data.json())
        .then(res => {
          setCoef(coef => ({
            ...coef,
            price2: res.pair.priceUsd
          }));    
          setPrev2(res.pair.priceChange.m5)          
        })

        if(data){
          setLink1(data.link1)
          setLink2(data.link2)
          // fetchTrades(data.time1, data.time2);
          // fetchData(data.time1, data.time2);
        }
      })
  
    const intervalId = setInterval(fetchData, 180000); 
  
    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);
  
  
  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <img className={s.blue} src="/images/blue.png" alt="" />
            <div className={s.text}>
                <h1 className={s.title}>Ends in<br></br> 
                {timeLeft.days}:{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}</h1>
                {
                  coef.price1 ? <>
                <h3 className={s.percent}>{coef ? (+coef.price1 / (+coef.price1 + +coef.price2) * 100).toString().slice(0, 4) : "" } %</h3>
                <div className={s.chance}>chance to win</div>
                {
                  link1 &&
                  <div 
  className={s.name} 
  onClick={() => window.open(link1, "_blank")}
>
  Trump Coin: {coef.price1 && coef.price1.toString().slice(0, 6)} $ 
  <img src={prev1 < 0 ? "/images/icons/redArrow.svg" : "/images/icons/greenArrow.svg"} alt="" />
</div>

                }
                <button className={s.liveBtn} onClick={() => {window.location.href = "#livetracker"; ReactGA.event({'category': 'Live Tracker', 'action': 'submit', 'label': 'label' }) }}>Live Check</button>
                {
                  link2 &&
                  <div 
  className={s.name1} 
  onClick={() => window.open(link2, "_blank")}
>
  Harris Coin: {coef.price2 && coef.price2.toString().slice(0, 6)} $ 
  <img src={prev2 < 0 ? "/images/icons/redArrow.svg" : "/images/icons/greenArrow.svg"} alt="" />
</div>

}
                <div className={s.chance}>chance to win</div>
                <h3 className={s.percent}>{coef ? (+coef.price2 / (+coef.price1 + +coef.price2) * 100).toString().slice(0, 4) : "" } %</h3>
                </> : <></>
                }

            </div>
            <img className={s.red} src="/images/red.png" alt="" />
        </div>
    </div>
  )
}

export default Peoples