import React, { useEffect, useState } from 'react'
import s from './Faq.module.sass'

import axios from '../../axios'

function Faq() {

  const [faq, setFaq] = useState()

    useEffect(() => {
      axios.get('/getFaq')
      .then(res => res.data)
      .then(data => {
        if(data){
          setFaq(data)
        }
      })
    }, [])
    const [isOpen, setIsOpen] = useState(null); // Состояние для открытия/закрытия subBlock

    const toggleSubBlock = (index) => {
      if(isOpen != null){
        setIsOpen(null)
      }else{

        setIsOpen(index); // Переключение состояния
      }
    };

    
  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <div className={s.looper}></div>
            <div className={s.right}></div>
            <div className={s.stars}></div>
            <div className={s.faq}>
                <div className={s.title} id='faq'>FAQ</div>

                {
                  faq && faq.map((elem, index) => 
                  <div className={s.block} key={index} onClick={() => toggleSubBlock(index)}>
                      <p>{elem.question}</p>
                      {/* <span>{elem.subQuestion} </span> */}
                      <div className={`${s.subBlock} ${isOpen == index ? s.open : ''}`}>
                          <p>{elem.answer}</p>
                      </div>
                      <img className={`${s.arrow} ${isOpen == index ? s.opened : ''}`} src="/images/25638 копия.png" alt="" />
                  </div>
                  )
                }

            </div>
        </div>
    </div>
  )
}

export default Faq