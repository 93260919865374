import React, { useEffect, useState } from 'react'
import s from './News.module.sass'

import { TwitterTimelineEmbed } from "react-twitter-embed";
import { Tweet } from 'react-tweet'
import axios from '../../axios'

import { Timeline } from 'react-twitter-widgets'
function News() {
  const [text, setText] = useState()
  const [xId, setXId] = useState(null)
  const [telegramId, setTelegramId] = useState(null)


    useEffect(() => {
        axios.get('/getNews')
        .then(res => res.data)
        .then(data => {
          if(data){
            console.log("XID",data.titles[0])
            setXId(data.titles[0].toString())
            setTelegramId(data.titles[1])
            setText(data.pars[0].replace(/\n/g, '<br>'))
          }
        })
          // Загрузка скрипта Telegram

  

      }, []);

      useEffect(() => {
        const telegramScript = document.createElement('script');
        telegramScript.src = "https://telegram.org/js/telegram-widget.js?22";
        telegramScript.setAttribute('async', 'true');
        telegramScript.setAttribute('data-telegram-post', `CryptoVoteUS/${telegramId}`);
        telegramScript.setAttribute('data-width', '100%');
        telegramScript.setAttribute('data-dark', '1');
        document.getElementById('telegram-widget').appendChild(telegramScript);
        return () => {
          document.getElementById('telegram-widget').innerHTML = ''; // Очищаем виджет Telegram
        };
      }, [telegramId])

  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <div className={s.light}></div>
            <div className={s.rightVector}></div>
            <div className={s.star}></div>
            <div className={s.title} id='news'>News</div>
            <div className={s.par} dangerouslySetInnerHTML={{ __html: text }}>
          
            </div>
        <div className={s.blocks}>
        <div id="telegram-widget"></div>             
        <section className="twitterContainer">
          {
            xId != null &&
            <Tweet id={xId} />
          }
    </section>

    <Timeline
  dataSource={{
    sourceType: 'profile',
    screenName: 'TwitterDev'
  }}
  options={{
    height: '400'
  }}
/>
        </div>
        </div>

    </div>
  )
}

export default News