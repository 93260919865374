import React, { useEffect, useState } from 'react'
import s from './LiveTracker.module.sass'
import axios from '../../axios'

function LiveTracker() {

  const [currentGraph, setCurrentGraph] = useState('trump')
  const [adres1, setAdres1] = useState(null)
  const [adres2, setAdres2] = useState(null)

  useEffect(() => {
    axios.get('/getCoin')
    .then(res => res.data)
    .then(data => {
      if(data){
        setAdres1(data.price1)
        setAdres2(data.price2)
      }
    })
  }, [])

  return (
    <div className={s.container}>
      <div className={s.innerContainer}>
        <div className={s.lines}>
          {/* <img src="/images/vectors/line1.svg" alt="" />
                <img src="/images/vectors/line2.svg" alt="" /> */}
          <div className={s.line1} id='livetracker'></div>
          <div className={s.line2}></div>
        </div>
        <div className={s.title}>Live Tracker</div>
        {/* <img className={s.graph} src="/images/график.svg" alt="" /> */}
        <div className={s.buttons}>
          <div className={s.left} onClick={() => setCurrentGraph('trump')}>TRUMP-CRYPTOVOTEUS</div>
          <div className={s.right} onClick={() => setCurrentGraph('harris')}>HARRIS-CRYPTOVOTEUS</div>
        </div>
        {
          currentGraph && currentGraph == 'trump' && adres1 &&
          <div id={s.dexscreener}><iframe style={{width: "80%", height: "700px", zIndex: "5"}} src={`https://dexscreener.com/solana/${adres1}?embed=1&theme=dark&trades=0&info=0`}></iframe></div>
        }
        {
          currentGraph && currentGraph == 'harris' && adres2 &&
          <div id={s.dexscreener}><iframe style={{width: "80%", height: "700px", zIndex: "5"}} src={`https://dexscreener.com/solana/${adres2}?embed=1&theme=dark&trades=0&info=0`}></iframe></div>
        }
        <div className={s.stars}>
        
          <div className={s.star1}></div>
          <div className={s.star2}></div>
        </div>
      </div>
    </div>
  )
}

export default LiveTracker