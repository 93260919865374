import React, { useEffect, useState } from 'react'
import s from './Coins.module.sass'
import axios from '../../axios'
function Coins() {

    const [pair1, setPair1] = useState('')
    const [pair2, setPair2] = useState('')
    const [name1, setName1] = useState('')
    const [name2, setName2] = useState('')


    const [link1, setLink1] = useState('')
    const [link2, setLink2] = useState('')


    useEffect(() => {
        axios.get('/getCoin')
        .then(res => res.data)
        .then(data => {
            if(data){
                setPair1(data.price1)
                setPair2(data.price2)
                setName1(data.time1)
                setName2(data.time2)
                setLink1(data.link1)
                setLink2(data.link1)
                console.log(data)
            }
        })
    }, [])

    const handleChange = (e, setter) => {
        setter(e.target.value)
    }

    const saveBtn = () => {
        axios.post('/updateCoin', {
            price1: pair1,
            price2: pair2,
            time1: name1,
            time2: name2,
            link1,
            link2
        })
        .then(res => res.data)
        .then(data => {
            if(data){
                alert('Успешно сохранено')
            }
        })
    }

  return (
    <div className={s.container}>
        <div className={s.title}>Coins</div>
        <div className={s.fields}>
            <p>Adress for graph (PAIR) 1:</p>
            <input onChange={(e) => handleChange(e, setPair1)} value={pair1} type="text"  />
            <p>Adress for graph (PAIR) 2:</p>
            <input onChange={(e) => handleChange(e, setPair2)} value={pair2} type="text"  />
            <p>Adress for coin (NAME) 1:</p>
            <input onChange={(e) => handleChange(e, setName1)} value={name1} type="text"  />
            <p>Adress for coin (NAME) 2:</p>
            <input onChange={(e) => handleChange(e, setName2)} value={name2} type="text"  />
            <div className={s.border}></div>
            <p>Link Trade On a DEX 1:</p>
            <input onChange={(e) => handleChange(e, setLink1)} value={link1} type="text"  />
            <p>Link Trade On a DEX 2:</p>
            <input onChange={(e) => handleChange(e, setLink2)} value={link2} type="text"  />

            <button onClick={saveBtn}>Сохранить</button>
        </div>
    </div>
  )
}

export default Coins