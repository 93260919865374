import React, { useEffect, useState } from 'react';
import s from './Timer.module.sass';
import axios from '../../axios';

function Timer() {
  const [date, setDate] = useState('');

  useEffect(() => {
    axios.get('/getDate')
      .then(res => res.data)
      .then(data => {
        if (data && data.date) {
          // Преобразуем ISO дату в формат yyyy-MM-dd
          const formattedDate = new Date(data.date).toISOString().split('T')[0];
          setDate(formattedDate);
        }
      })
      .catch(err => console.error('Ошибка при получении даты:', err));
  }, []); // Добавьте пустой массив зависимостей, чтобы запрос выполнялся один раз при монтировании компонента

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const saveDate = async () => {
    try {
      if (date !== "") {
        axios.post('/setDate', {
          date
        })
        .then((res) => res.data)
        .then((data) => {
          if (data) {
            alert('Дата успешно сохранена');
          } else {
            alert('Ошибка при сохранении даты');
          }
        })
      } else {
        alert("Выставите дату");
      }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  return (
    <div className={s.container}>
      <div className={s.title}>Выставите дату</div>
      <input value={date} type="date" onChange={handleDateChange} />
      <button onClick={saveDate}>Сохранить дату</button>
    </div>
  );
}

export default Timer;
