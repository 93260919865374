import React, { useEffect, useState } from 'react'
import s from './Works.module.sass'
import axios from '../../axios'

function Works() {

    useEffect(() => {   
        axios.get('/getWorks')
        .then((res) => res.data)
        .then((data) => {
            console.log(data)
            if(data){
                setMain(data.main)
                setText1(data.text1)
                setText2(data.text2)
                setText3(data.text3)

            }
        })

    }, [])

    const [main, setMain] = useState()
    const [text1, setText1] = useState()
    const [text2, setText2] = useState()
    const [text3, setText3] = useState()

    const handleChange = (e, setter) => {
        setter(e.target.value)
    }
    const save = () => {
        axios.post('/updateWorks', {
            main, text1: "", text2: "", text3: ""
        })
        .then(res => res.data)
        .then(data => {
            if(data){
            alert("Успешно обновлены поля")
        }})
        .catch((err) => alert("Произошла ошибка"))
    }


  return (
    <div className={s.container}>
        <div className={s.title}>Поля How It Works</div>
        <div className={s.fields}>
            <p>Основное поле текста: </p>
            <textarea value={main} onChange={(e) => handleChange(e, setMain)} ></textarea>
        </div>
        {/* <div className={s.fields}>
            <p>Поле для текста 1: </p>
            <textarea value={text1} onChange={(e) => handleChange(e, setText1)} ></textarea>
        </div> */}
        {/* <div className={s.fields}>
            <p>Поле для текста 2: </p>
            <textarea value={text2} onChange={(e) => handleChange(e, setText2)} ></textarea>
        </div>
        <div className={s.fields}>
            <p>Поле для текста 3: </p>
            <textarea value={text3} onChange={(e) => handleChange(e, setText3)} ></textarea>
        </div> */}
        <button onClick={save}>Сохранить</button>
    </div>
  )
}

export default Works