import React, { useEffect, useState } from 'react'
import s from './WhitePaper.module.sass'

import axios from '../../axios'


function WhitePaper() {

    const [text, setText] = useState()

    useEffect(() => {
        axios.get('/getPaper')
        .then((res) => res.data)
        .then((data) => {
            if(data){
                setText(data.text.replace(/\n/g, '<br>'))
            }
        })
    }, [])

  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <div className={s.title} id='whitepaper11'>Whitepaper</div>
            {
                text && 
            <div className={s.par} dangerouslySetInnerHTML={{ __html: text }} ></div>
            }
            
        </div>
    </div>
  )
}

export default WhitePaper