import React, { useEffect, useState } from 'react'
import s from './HowWorks.module.sass'
import axios from '../../axios'
function HowWorks() {
  const [works, setWorks] = useState()

  useEffect(() => {
    axios.get('/getWorks')
    .then(res => res.data)
    .then(data => {
      if(data){
        setWorks(data.main.replace(/\n/g, '<br>'))
      }
    })
  }, [])

  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <div className={s.rightLight}></div>
            <div className={s.light} id='how'></div>
            <div className={s.title}>How It Works</div>
            {
              works &&
            <div className={s.par} dangerouslySetInnerHTML={{ __html: works }}>

            </div>
            }
            <div className={s.blocks}>
            {/* {
              works && works.text1 &&
                <div className={s.block} dangerouslySetInnerHTML={{ __html: works.text1 }}>
                </div>
            } */}
            {/* {
              works && works.text2 &&
                <div className={s.block} dangerouslySetInnerHTML={{ __html: works.text2 }}>
                </div>
            }
            {
              works && works.text3 &&
                <div className={s.block} dangerouslySetInnerHTML={{ __html: works.text3 }}>
                </div>
            } */}
            </div>
        </div>
    </div>
  )
}

export default HowWorks