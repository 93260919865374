import React, { useEffect, useState } from 'react'
import s from './Faq.module.sass'
import axios from '../../axios'

function Faq() {

    const [faq, setFaq] = useState([])
    const [currentFaq, setCurrentFaq] = useState(null)

    // Функция для обработки изменений в полях
    const handleChangeQuestion = (e, field) => {
        setCurrentFaq({
            ...currentFaq,
            [field]: e.target.value // Обновляем нужное поле (question, subQuestion или answer)
        });
    }

    // Получаем данные FAQ при монтировании компонента
    useEffect(() => {
        axios.get('/getFaq')
        .then(res => res.data)
        .then(data => {
            if(data){
                setFaq(data)
            }
        })
    }, [])

    // Функция для сохранения изменений (создание или обновление)
    const saveChanges = () => {
        if (currentFaq._id) {
            // Если есть _id, обновляем существующий FAQ
            axios.post('/updateFaq', {
                id: currentFaq._id,
                question: currentFaq.question,
                subQuestion: currentFaq.subQuestion,
                answer: currentFaq.answer,
            })
            .then(() => {
                alert("Изменения успешно сохранены");
                setCurrentFaq(null); // Закрываем попап после сохранения
                window.location.reload();
            })
            .catch((err) => {
                alert("Произошла ошибка при сохранении изменений");
            });
        } else {
            // Если нет _id, создаем новый FAQ
            axios.post('/createFaq', {
                question: currentFaq.question,
                subQuestion: currentFaq.subQuestion,
                answer: currentFaq.answer,
            })
            .then(() => {
                alert("Новый FAQ успешно создан");
                setCurrentFaq(null); // Закрываем попап после создания
                window.location.reload();
            })
            .catch((err) => {
                alert("Произошла ошибка при создании FAQ");
            });
        }
    }

    const deleteFaq = (id) => {
        axios.post('/deleteFaq', {
            id
        })
        .then(res => res.data)
        .then(data => {
            if(data){
                let filteredFaq = faq.filter((elem) => elem._id != id)
                setFaq(filteredFaq)
            }
        })
    }

    return (
    <div className={s.container}>
        <div className={s.faqs}>
            {
                faq && faq.map((elem, index) => 
                    <div className={s.faq} key={index}>
                        <p onClick={() => setCurrentFaq(elem)}>{elem.question}</p>
                        <p onClick={() => deleteFaq(elem._id)}>удалить</p>
                    </div>
                )
            }

            {
                currentFaq != null &&
            <div className={s.popup}>
                    <img className={s.closeBtn} onClick={() => setCurrentFaq(null)}  src="/images/delete-sign.png" alt=""/>
                    
                    <p>Основной вопрос</p>
                    <input 
                        type="text" 
                        value={currentFaq.question || ''} 
                        onChange={(e) => handleChangeQuestion(e, 'question')} 
                    />
                    
                    <p>Под вопрос</p>
                    <input 
                        type="text" 
                        value={currentFaq.subQuestion || ''} 
                        onChange={(e) => handleChangeQuestion(e, 'subQuestion')} 
                    />
                    
                    <p>Ответ</p>
                    <input 
                        type="text" 
                        value={currentFaq.answer || ''} 
                        onChange={(e) => handleChangeQuestion(e, 'answer')} 
                    />
                
                    <button onClick={saveChanges}>Сохранить</button>
            </div>
            }

            {
                currentFaq != null &&
                <div className={s.shadow}></div>
            }
            <button onClick={() => setCurrentFaq({question: "", answer: "", subQuestion: ""})} className={s.add}>Создать вопрос</button>
        </div>
    </div>
  )
}

export default Faq
