import React, { useEffect, useState } from 'react'
import s from './BuySell.module.sass'
import axios from '../../axios'

function BuySell() {



    const [coef, setCoef] = useState({
        time1: "",
        price1: "",
        time2: "",
        price2: ""
      })
      

      const [secPrice, setSecPrice] = useState({
        buy1: "",
        buy2: "",
        sell1: "",
        sell2: ""
      })

      const [link1, setLink1] = useState('')
      const [link2, setLink2] = useState('')

      useEffect(() => {
        const fetchData = async (adres1, adres2) => {
          try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("X-API-KEY", "BQY5aq8APGbaBpByE8jKu3EPVTgIvG7c");
            myHeaders.append("Authorization", "Bearer ory_at_YamYsAO7b9jEBnXGI8-2kJekEwimTxLLpHXU7z7pglY.3t25YHD831pmzeLj23N3bGVNY4WNMYxAWFMNYK7RTEY");
      
            const rawQuery1 = JSON.stringify({
                 "query": `query MyQuery {\n  Solana {\n    DEXTradeByTokens(\n      where: {Trade: {Currency: {MintAddress: {is: \"${adres1}\"}}}}\n      limit: {count: 10}\n      orderBy: {descending: Block_Time}\n    ) {\n      Block {\n        Time\n      }\n      Trade {\n        Currency {\n          Name\n          Symbol\n        }\n        PriceInUSD\n        Side {\n          Type(selectWhere: {})\n        }\n      }\n    }\n  }\n}\n`,
   "variables": "{}"
            });
      
            const rawQuery2 = JSON.stringify({
   "query": `query MyQuery {\n  Solana {\n    DEXTradeByTokens(\n      where: {Trade: {Currency: {MintAddress: {is: \"${adres2}\"}}}}\n      limit: {count: 10}\n      orderBy: {descending: Block_Time}\n    ) {\n      Block {\n        Time\n      }\n      Trade {\n        Currency {\n          Name\n          Symbol\n        }\n        PriceInUSD\n        Side {\n          Type(selectWhere: {})\n        }\n      }\n    }\n  }\n}\n`,
   "variables": "{}"
            });
      
            const requestOptions = {
              method: "POST",
              headers: myHeaders,
              redirect: "follow"
            };
      
            // Параллельное выполнение запросов
            const [result1, result2] = await Promise.all([
              fetch("https://streaming.bitquery.io/eap", { ...requestOptions, body: rawQuery1 }),
              fetch("https://streaming.bitquery.io/eap", { ...requestOptions, body: rawQuery2 })
            ]);
      
            const data1 = await result1.json();
            const data2 = await result2.json();
      
             let buy1 = data1.data.Solana.DEXTradeByTokens.find(elem => elem.Trade.Side.Type == "buy")
             let buy2 = data2.data.Solana.DEXTradeByTokens.find(elem => elem.Trade.Side.Type == "buy")
             let sell1 = data1.data.Solana.DEXTradeByTokens.find(elem => elem.Trade.Side.Type == "sell")
             let sell2 = data2.data.Solana.DEXTradeByTokens.find(elem => elem.Trade.Side.Type == "sell")

            

             setSecPrice({
              name1: buy1.Trade.Currency.Name,
              name2: buy2.Trade.Currency.Name,
              buy1: buy1.Trade.PriceInUSD,
              buy2: buy2.Trade.PriceInUSD,
              sell1: sell1.Trade.PriceInUSD,
              sell2: sell2.Trade.PriceInUSD
             })


            
            setCoef({
              time1: data1.data.Solana.DEXTradeByTokens[0].Block.Time,
              time2: data2.data.Solana.DEXTradeByTokens[0].Block.Time,
              price1: data1.data.Solana.DEXTradeByTokens[0].Trade.PriceInUSD,
              price2: data2.data.Solana.DEXTradeByTokens[0].Trade.PriceInUSD,
            });
          } catch (error) {
            console.error('Ошибка:', error);
          }
        };
      
        // Вызов fetchData сразу при загрузке компонента
        axios.get('/getCoin')
        .then(res => res.data)
        .then(data => {
          fetch(`https://api.dexscreener.com/latest/dex/pairs/solana/${data.price1}`)
          .then(data => data.json())
          .then(res => {
            setCoef(coef => ({
              ...coef,
              price1: res.pair.priceUsd
            }));        
            
          })
          fetch(`https://api.dexscreener.com/latest/dex/pairs/solana/${data.price2}`)
          .then(data => data.json())
          .then(res => {
            setCoef(coef => ({
              ...coef,
              price2: res.pair.priceUsd
            }));        
          })
  
          if(data){
            setLink1(data.link1)
            setLink2(data.link2)
            // fetchTrades(data.time1, data.time2);
            // fetchData(data.time1, data.time2);
          }
        })
      
        // Устанавливаем интервал для обновления данных каждые 15 минут (900000 мс)
        const intervalId = setInterval(fetchData, 120000); // 15 минут
      
        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
      }, []);

  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <div className={s.blocks}>
                <div className={s.left}>
                    <p style={{margin: '10px'}}>$TRUMP-C</p>
                    {
                      secPrice &&
                      <p style={{margin: '10px'}}><span style={{color: "white"}}>{coef.price1.toString().slice(0, 6)}$</span></p>

                    }
                    {
                      link1 &&
                      <button onClick={() => window.open(link1, "_blank")}>Trade on a DEX</button>

                    }
                </div>
                <div className={s.center}>
                    <div className={s.leftSide}>
                        <p>Esitemated income Trump: <span>{coef ? (+coef.price1 / (+coef.price1 + +coef.price2) * 100 + 100).toString().slice(0, 3) : "" }%</span></p>
                    </div>
                    <div className={s.rightSide}>
                        <p>Esitemated income Harris: <span>{coef ? (+coef.price2 / (+coef.price1 + +coef.price2) * 100 + 100).toString().slice(0, 3) : "" }%</span></p>
                    </div>
                    {/* <img src="/images/vectors/leftTriangle.svg" alt="" />
                    <img src="/images/vectors/rightTriangle.svg" alt="" /> */}
                </div>
                <div className={s.right}>
                  {
                    secPrice &&
                    <p style={{margin: '10px'}}>HARRIS</p>
                  }
                    {
                      secPrice &&
                    <p style={{margin: '10px'}}><span style={{color: "white"}}>{coef.price2.toString().slice(0, 6)}$</span></p>
                    }
                    {
                      link2 &&
                      <button onClick={() => window.open(link2, "_blank")}>Trade on a DEX</button>

                    }
                </div>
            </div>
        </div>

    </div>
  )
}

export default BuySell