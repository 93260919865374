import Header from "./components/Header/Header";
import Peoples from "./components/Peoples/Peoples";
import LiveTracker from "./components/LiveTracker/LiveTracker";
import BuySell from "./components/BuySell/BuySell";
import News from "./components/News/News";
import HowWorks from "./components/HowWorks/HowWorks";
import Faq from "./components/Faq/Faq";
import BottomSide from "./components/BottomSide/BottomSide";
import { Routes, Route } from "react-router-dom";
import AdminPage from "./pages/AdminPage/AdminPage";
import WhitePaper from "./components/WhitePaper/WhitePaper";
import { useEffect } from "react";
import ReactGA from 'react-ga4'


function App() {

  
  
  ReactGA.initialize('G-XDD6TZ52FN')

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Home"
  })

  // useEffect(() => {
  //   if (window.location.protocol === "http:") {
  //     window.location.href = window.location.href.replace("http:", "https:");
  //   }
  // }, []);

  // useEffect(() => {
    // const script = document.createElement('script');
    // script.src = "https://www.googletagmanager.com/gtag/js?id=G-HP1SY3W10Z";
    // script.async = true;
    // document.body.appendChild(script);

    // window.dataLayer = window.dataLayer || [];
    // function gtag() {
    //   window.dataLayer.push(arguments);
    // }
    // gtag('js', new Date());
    // gtag('config', 'G-HP1SY3W10Z');
  // }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={
          <>
            <Header />
            <Peoples />
            <LiveTracker />
            <BuySell />
            <News />
            <HowWorks />
            <Faq />
            <WhitePaper></WhitePaper>
            <BottomSide />

          </>
        } />
        <Route path="/rfdoah3wklrmfwedvhbsdiufasd" element={<AdminPage/>}></Route>
        
      </Routes>
    </div>
  );
}

export default App;
