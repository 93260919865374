import React, { useEffect, useState } from 'react'
import s from './Terms.module.sass'
import axios from '../../axios'

function Terms() {

    useEffect(() => {
        axios.get('/getTerms')
        .then((res) => res.data)
        .then(data => {
            if(data){
                setTerms(data.text)
            }
        })
        axios.get('/getContact')
        .then((res) => res.data)
        .then(data => {
            if(data){
                setContact(data.text)
            }
        })
        axios.get('/getPaper')
        .then((res) => res.data)
        .then(data => {
            if(data){
                setPaper(data.text)
            }
        })
    }, [])

    const [terms, setTerms] = useState('')
    const [contact, setContact] = useState('')
    const [paper, setPaper] = useState('')

    const handleChange = (e, setter) => {
        setter(e.target.value)
    }
    
    const saveterms = () => {
        axios.post('/updateTerms', {text: terms})
        .then((res) => res.data)
        .then((data) => {
            if(data){
                alert("Успешно сохранено")
            }
        })
    }

    const savecontact = () => {
        axios.post('/updateContact', {text: contact})
        .then((res) => res.data)
        .then((data) => {
            if(data){
                alert("Успешно сохранено")
            }
        })
    }

    const savepaper = () => {
        axios.post('/updatePaper', {text: paper})
        .then((res) => res.data)
        .then((data) => {
            if(data){
                alert("Успешно сохранено")
            }
        })
    }


  return (
    <div className={s.container}>
        <div className={s.fields}>
            <div className={s.title}>Terms</div>
            <textarea type="text" value={terms || ''} onChange={(e) => handleChange(e, setTerms)}/>
            <button onClick={saveterms}>Сохранить</button>
        </div>
        <div className={s.fields}>
            <div className={s.title}>Contact</div>
            <textarea type="text" value={contact} onChange={(e) => handleChange(e, setContact)}/>
            <button onClick={savecontact}>Сохранить</button>
        </div>
        <div className={s.fields}>
            <div className={s.title}>White Paper</div>
            <textarea type="text" value={paper} onChange={(e) => handleChange(e, setPaper)}/>
            <button onClick={savepaper}>Сохранить</button>
        </div>

    </div>
  )
}

export default Terms