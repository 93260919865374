import React from 'react'
import s from './Header.module.sass'

function Header() {
  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <img className={s.logo} style={{cursor: 'pointer'}} onClick={() => window.location.reload()} src="/images/logo.svg" alt="" />
            <div className={s.nav}>
                <a href='#livetracker'>Live tracker</a>
                <a href='#news'>News</a>
                <a href='#how'>How it works</a>
                <a href='#faq'>FAQ</a>
                <a href='#whitepaper11'>Whitepaper</a>
            </div>
            <div className={s.icons}>
            <img onClick={() => window.open("https://x.com/CryptoVoteUS", "_blank")} src="/images/icons/Twitter.svg" alt="" />
<img onClick={() => window.open("https://t.me/CryptoVoteUS", "_blank")} src="/images/icons/Telegram.svg" alt="" />
<img onClick={() => window.open("https://medium.com/@CryptoVoteUS", "_blank")} src="/images/icons/medium.png" style={{width: "30px", height: "30px"}} alt="" />
<img onClick={() => window.open("https://coinmarketcap.com/community/profile/CryptoVoteUS/", "_blank")} src="/images/icons/cap.png" style={{width: "25px", height: "25px"}} alt="" />


            </div>

            
        </div>
    </div>
  )
}

export default Header