import React, { useEffect, useState } from 'react';
import s from './News.module.sass';
import axios from '../../axios';

function News() {

    const [titles, setTitles] = useState(["", "", ""]); // Для хранения заголовков
    const [pars, setPars] = useState(["", "", ""]);     // Для хранения текстов
    const [links, setLinks] = useState(["", "", ""]);   // Для хранения ссылок

    useEffect(() => {   
        axios.get('/getNews')
        .then((res) => res.data)
        .then((data) => {
            if (data) {
                setTitles(data.titles);
                setPars(data.pars);
                setLinks(data.links);
            }
        })
        .catch((err) => console.error("Ошибка при получении данных", err));
    }, []);

    const handleTitleChange = (index, value) => {
        const newTitles = [...titles];
        newTitles[index] = value;
        setTitles(newTitles);
    };

    const handleParChange = (index, value) => {
        const newPars = [...pars];
        newPars[index] = value;
        setPars(newPars);
    };

    const handleLinkChange = (index, value) => {
        const newLinks = [...links];
        newLinks[index] = value;
        setLinks(newLinks);
    };

    const save = () => {
        axios.post('/updateNews', {
            titles, pars, links
        })
        .then(res => res.data)
        .then(data => {
            if (data) {
                alert("Успешно обновлены поля");
            }
        })
        .catch((err) => alert("Произошла ошибка при сохранении данных"));
    };

    return (
        <div className={s.container}>
            <div className={s.title}>Поля News</div>

            {/* Поля для X (Twitter) */}
            <div className={s.fields}>
                <p>ID для X (Twitter): </p>
                <input value={titles[0]} onChange={(e) => handleTitleChange(0, e.target.value)} />
            </div>
            <div className={s.fields}>
                <p>ID для Telegram: </p>
                <input value={titles[1]} onChange={(e) => handleTitleChange(1, e.target.value)} />
            </div>
            <div className={s.fields}>
                <p>Текст: </p>
                <textarea value={pars[0]} onChange={(e) => handleParChange(0, e.target.value)} />
            </div>
            {/* <div className={s.fields}>
                <p>Ссылка для X (Twitter): </p>
                <input value={links[0]} onChange={(e) => handleLinkChange(0, e.target.value)} />
            </div> */}

            {/* <div className={s.border}></div> */}

            {/* Поля для Telegram */}
            {/* <div className={s.fields}>
                <p>Текст для Telegram: </p>
                <textarea value={pars[1]} onChange={(e) => handleParChange(1, e.target.value)} />
            </div>
            <div className={s.fields}>
                <p>Ссылка для Telegram: </p>
                <input value={links[1]} onChange={(e) => handleLinkChange(1, e.target.value)} />
            </div> */}

            <div className={s.border}></div>

            {/* Поля для Discord */}
            {/* <div className={s.fields}>
                <p>Заголовок для Discord: </p>
                <input value={titles[2]} onChange={(e) => handleTitleChange(2, e.target.value)} />
            </div>
            <div className={s.fields}>
                <p>Текст для Discord: </p>
                <textarea value={pars[2]} onChange={(e) => handleParChange(2, e.target.value)} />
            </div>
            <div className={s.fields}>
                <p>Ссылка для Discord: </p>
                <input value={links[2]} onChange={(e) => handleLinkChange(2, e.target.value)} />
            </div> */}

            <button onClick={save}>Сохранить</button>
        </div>
    );
}

export default News;
